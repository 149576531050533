import * as React from "react";
import RoundedButton from '../sub-components/roundedButton';
import * as styles from './contactLoanOfficer.module.scss';

const ContactLoanOfficer = ({ loanOfficer, version }) => {

    return (
        <>
            {loanOfficer?.applyLink && loanOfficer?.continueApplicationLink &&
                <div className={styles.container}>
                    <div className={styles.wrap}>
                        <div className={styles.bg}>
                            <div className={styles.content}>
                                <div className={styles.sectionTitle}>
                                    <div>Ready to get started?</div>
                                    <span className={styles.greenLine}></span>
                                </div>
                                <div className={styles.sectionCTAContent}>
                                    <div>Contact {loanOfficer.firstName} today and get pre-qualified</div>
                                    <div className={styles.buttonRow}>
                                        <RoundedButton color="greenToBlue" text="Apply Now" destination={loanOfficer.applyLink} />
                                        <RoundedButton color="transparentWithGreen" text="Schedule Appointment" destination={loanOfficer.continueApplicationLink} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default ContactLoanOfficer;