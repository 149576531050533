import * as React from "react";
import RoundedButton from '../sub-components/roundedButton';
import * as styles from './stackedInformationalSection.module.scss';

const StackedInformationalSection = ({ title, description, buttonOneText, buttonOneLink, buttonTwoText, buttonTwoLink, version, loanOfficer }) => {
    if (version === 2) {
        return (
            <div className={styles.background}>
                <div className={styles.container}>
                    <div className={styles.solidBg}>
                        <img src="/assets/exploreLoanCalculators/green-bg.png" alt="green rectangle" />
                    </div>
                    <div className={styles.stockBg}>
                        <img src="/assets/exploreLoanCalculators/stock-explore-loan-calc.png" alt="loan officer" />
                        <div className={styles.content}>
                            <div className={styles.sectionTitle}>
                                <div>{title}</div>
                                <span className={styles.greenLine}></span>
                            </div>
                            <div className={styles.sectionCTAContent}>
                                <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }}></div>
                                <div className={styles.buttonRow}>
                                    {buttonOneText && buttonOneLink && (
                                        <RoundedButton color="greenToBlue" text={buttonOneText} loanOfficer={loanOfficer} destination={buttonOneLink} />
                                    )}
                                    {buttonTwoText && buttonTwoLink && (
                                        <RoundedButton color="transparentWithGreen" text={buttonTwoText} destination={buttonTwoLink} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.backgroundV2}>
                <div className={styles.container}>
                    <div className={styles.solidBg}>
                        <img src="/assets/exploreLoanCalculators/green-bg.png" alt="green rectangle" />
                    </div>
                    <div className={styles.stockBg}>
                        <img src="/assets/exploreLoanCalculators/stock-explore-loan-calc.png" alt="loan officer" />
                        <div className={styles.content}>
                            <div className={styles.sectionTitle}>
                                <div>{title}</div>
                                <span className={styles.greenLine}></span>
                            </div>
                            <div className={styles.sectionCTAContent}>
                                <div dangerouslySetInnerHTML={{ __html: description }}></div>
                                <div className={styles.buttonRow}>
                                    {buttonOneText !== null && (
                                        <RoundedButton color="greenToBlue" text={buttonOneText} loanOfficer={loanOfficer} destination="/calculator" />
                                    )}
                                    {buttonTwoText !== null && (
                                        <RoundedButton color="transparentWithGreen" text={buttonTwoText} destination="https://google.com" />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default StackedInformationalSection;